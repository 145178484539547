.logo {
  border-radius: 50%;
  height: 165px;
  border: 2px solid #aaa;
}

.main-nav {
  background-color: #3d1767;
  padding: 20px;

  .nav-link {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    margin-left: 35px;
    margin-right: 35px;

    &:active,
    &:hover {
      color: purple;
    }

    &.disabled {
      color: #ddd;
      font-weight: normal;
    }
  }

  .navbar-toggler {
    color: #fff;
    background-color: silver;
  }
}