.signup-table {
  margin: 0 auto;
  width: 100%;
  overflow-wrap: break-word;

  th,
  td {
    border: 1px solid black;
    padding: 8px;
  }
}